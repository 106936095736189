<template>
  <a href="#">
    <div
      :class="`${classes} group overflow-hidden rounded-3xl px-10 pb-14 pt-16 transition-colors`"
    >
      <Icon :icon="icon" class="mb-8 h-[2.5rem] w-[2.5rem]"></Icon>
      <div class="flex flex-col justify-between">
        <h2 class="mb-10 min-h-[7rem] text-3xl font-bold">
          <slot></slot>
        </h2>
        <div class="justify-end">
          <p class="tiny mb-2 font-medium">EN SAVOIR +</p>
          <div class="h-0.5 w-24 rounded-full bg-white/40">
            <div
              class="transition-width transition-slowest ease h-0.5 w-0 rounded-full bg-white group-hover:w-full"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </a>
</template>
<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
      default: "info"
    },
    design: {
      type: String,
      required: true,
      default: "1"
    }
  },
  data() {
    return {};
  },
  computed: {
    classes() {
      const styleClasses = {
        1: "bg-primary-light hover:bg-primary hover:text-white-light text-[#4C6287] text-white active:bg-primary-lighter active:text-white-light",
        2: "bg-secondary-light hover:bg-secondary hover:text-white-light text-[#4C6287] text-white active:bg-secondary-lighter active:text-white-light",
        3: "bg-ternary-light hover:bg-ternary hover:text-white-light text-[#4C6287] text-white active:bg-ternary-lighter active:text-white-light"
      };

      return `${styleClasses[this.design]}`;
    }
  }
};
</script>
