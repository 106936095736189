<template>
  <div>
    <Skeleton shape="circle" v-if="loading" size="9rem"></Skeleton>
    <div
      v-else
      class="text-primary flex h-[9rem] w-[9rem] items-center justify-center bg-contain bg-no-repeat text-[2.5rem] font-bold"
      style="background-image: url('/images/BACKGROUND CHIFFRES.png')"
    >
      {{
        !isNumber
          ? "?"
          : $n(number, { minimumFractionDigits: 0, maximumFractionDigits: 0 })
      }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    number: {
      type: Number,
      required: true,
      default: 0,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    isNumber() {
      return typeof this.number === "number";
    },
  },
};
</script>
